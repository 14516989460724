import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

function Footer(props) {
    return (
        <>
            <footer className="footer-area footer-style-01 bg_color--6">
                <div className="im-call-to-action-area ptb--70 im-separator">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                <div className="inner">
                                    <h2 className="text-white mb--0">
                                        Rezervirajte obisk vpogleda v izjemno
                                        pestrost geološke dediščine.
                                    </h2>
                                    <p className="mt-2 p-4 saturday-special-offer">
                                        Vsako soboto ob 10:00 možnost ogleda Izvoznega rova rudnika brez predhodne najave!
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                                <div className="call-to-cation-tbn mt_md--20 mt_sm--20 mx-sm-auto mx-auto">
                                    <Link
                                        className="btn-default btn-large btn-border btn-opacity"
                                        to="/kontakt-in-informacije"
                                    >
                                        Rezerviraj obisk
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-wrapper ptb--70">
                    <div className="container">
                        <div className="row d-sm-flex justify-content-end">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 text-center order-sm-0 mb-sm-4">
                                <div className="ft-text">
                                    <div className="row d-flex justify-content-center align-items-end">
                                        <div className="col-6">
                                            <a target="_blank" href="https://visitlitija.si/?fbclid=IwAR1UNaiom5hFfNClQQvE0gVbpuH7XdFdcnyQ4ctwxZZFU6COoG6qxTdT
liI">
                                                <StaticImage
                                                    src="../../assets/images/rudnik/tic-litija-logo-bel.png"
                                                    alt="TIC Litija logo"
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-100 p-2"
                                                    loading="lazy"
                                                />
                                            </a>
                                        </div>

                                        <div className="col-6">
                                            <a
                                                href="https://srce-slovenije.si/"
                                                target="_blank"
                                            >
                                                <StaticImage
                                                    src="../../assets/images/rudnik/logo-razvojni-center-srca-slovenije-bel.png"
                                                    alt="Logo razvojni center Slovenije "
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-100"
                                                    loading="lazy"
                                                />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-6 greensafe-logo">
                                            <a href="https://www.slovenia.info/sl" target="_blank">
                                                <StaticImage
                                                    src="../../assets/images/rudnik/greensafe.png"
                                                    alt="Green Safe"
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-80"
                                                    loading="lazy"
                                                />
                                            </a>
                                        </div>

                                        <div className="col-6 safetravel-logo">
                                            <a href="https://www.slovenia.info/sl" target="_blank">
                                                <StaticImage
                                                    src="../../assets/images/rudnik/safetravels.png"
                                                    alt="Safe travels"
                                                    placeholder="blurred"
                                                    imgClassName="img-fluid w-60"
                                                    loading="lazy"
                                                />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="row d-flex justify-content-start align-items-center mb-5">
                                        <a href="http://las-srceslovenije.si/sl_SI/" target="_blank">
                                            <StaticImage
                                                src="../../assets/images/rudnik/las-logo-inverted.png"
                                                alt="Safe travels"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-60"
                                                loading="lazy"
                                            />
                                        </a>
                                    </div>

                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-6">
                                            <StaticImage
                                                src="../../assets/images/rudnik/slovenia-destination-bronze.png"
                                                alt="Slovenia destination bronze logo"
                                                placeholder="blurred"
                                                imgClassName="img-fluid w-60"
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--40 text-center order-sm-2">
                                <div className="footer-link">
                                    <h4>Kazalo strani</h4>
                                    <ul className="ft-link">
                                        <li>
                                            <Link to="/">Spoznaj rudnik</Link>
                                        </li>
                                        <li>
                                            <Link to="/obisci-rudnik/glavni-rov">
                                                Glavni rov
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/obisci-rudnik/izvozni-rov">
                                                Izvozni rov
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/obisci-rudnik/animirana-vodenja">
                                                Animirana vodenja
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/obisci-rudnik/kulinarika">
                                                Kulinarika
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/obisci-rudnik/tradicionalni-dogodki">
                                                Tradicionalni dogodki
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--0 mt_sm--0 text-center order-sm-3">
                                <div className="footer-link">
                                    <ul className="ft-link ft-link-second-row">
                                        <li>
                                            <Link to="/obisci-rudnik/trgovina-in-spominki">
                                                Trgovina in spominki
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/razisci-rudnik-sitarjevec">
                                                Razišči rudnik
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cenik">Cenik</Link>
                                        </li>
                                        <li>
                                            <Link to="/kontakt-in-informacije">
                                                Kontakt in informacije
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/izjava-o-dostopnosti">
                                                Izjava o dostopnosti
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/politika-varstva-osebnih-podatkov">
                                                Politika varstva osebnih
                                                podatkov
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40 text-center order-sm-1 mb-sm-4">
                                <div className="footer-link">
                                    <h4 className="d-sm-none d-lg-inline-block">
                                        Kontakt
                                    </h4>
                                    <ul className="ft-link">
                                        <li>
                                            <a href="mailto:info@visitlitija.si">
                                                info@visitlitija.si
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+38651312739">
                                                051 312 739
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+38670700484">
                                                070 700 484
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://visitlitija.si">
                                                Visit Litija
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="social-share-inner mt--20">
                                        <ul className="social-share social-style--2 d-flex justify-content-center liststyle">
                                            <li>
                                                <a href="https://www.facebook.com/rudniksitarjevec/">
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="currentColor"
                                                        viewBox="0 0 320 512"
                                                        height="1em"
                                                        width="1em"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/rudnik_sitarjevec/">
                                                    <svg
                                                        stroke="currentColor"
                                                        fill="currentColor"
                                                        viewBox="0 0 448 512"
                                                        height="1em"
                                                        width="1em"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container subfooter text-center">
                        <p className="subfooter-content">
                            <a href="/en">Rudnik Sitarjevec Litija © 2023.</a>{" "}
                            Produkcija spletne strani{" "}
                            <a
                                className="action-link"
                                href="https://spletne.si"
                                target="_blank"
                            >
                                spletne.si
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </footer>
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    );
}

export default Footer;
